import React, {FC, JSX} from "react";
import {Facebook, Github, Google, Linkedin, Twitter,} from "../../../../../svg";
import cls from "../../../../account/account.module.scss"
import {TranslationsType} from "../../../../../types/homePageTypes";

const socials: Record<string, JSX.Element> = {
    "Facebook": <Facebook/>,
    "Google": <Google/>,
    "Twitter": <Twitter/>,
    "Linkedin": <Linkedin/>,
    "Github": <Github/>,
}

interface ISocialLogin {
    translationsData: TranslationsType,
    isSocialLinksActive: Array<Record<string, string>>,
    dbName: string
}

const CustomSocialLogin: FC<ISocialLogin> = (
    {
        translationsData,
        isSocialLinksActive,
        dbName
    }
) => {
    const customerLoginWithProvider = (provider: string) => {
        try {
            fetch(`https://${dbName}/api/customer/social-login/${provider}`)
                .then((response) => response.json())
                .then((res) => {
                    window.location = res.url
                })
        } catch (err) {
            console.log(err, "-----#############------error-----#############------ in customerLoginWithProvider")
        }
    }
    const showOrNotSocialToggle = {
        "true": (
            <div className={cls.socials_content}>
                <div className={cls["social-line-block"]}>
                    <div className={cls.social_line}></div>
                    <span className={cls["social_line-span"]}>
                        {translationsData?.SignInWith}
                    </span>
                    <div className={cls.social_line}></div>
                </div>
                <div className={cls["social-links_line"]}>
                    {isSocialLinksActive?.map(({key}, index: number) => (
                        <div
                            key={index}
                            className={cls["links_icons"]}
                            onClick={() => customerLoginWithProvider(key.toLowerCase())}
                        >
                            {socials[key]}
                        </div>
                    ))}
                </div>
            </div>
        ),
        "false": null
    }

    return showOrNotSocialToggle[`${isSocialLinksActive?.length > 0}`]
};

export default CustomSocialLogin;


//TODO!!! Will check this issue , is my token show in url or this functionality redundant
// useEffect(() => {
//     if (router.query.token) {
//         dispatch(setAuth(true));
//         dispatch(setToken(router.query.token));
//         dispatch(setPopup(false));
//         router.push("/account/profile/");
//         fetch(
//             `https://${httpUrl}/api/checkout/cart?token=${router.query.token}&api_token=${cartToken.cartToken}`
//         )
//             .then((responce) => responce.json())
//             .then((resUser) => {
//                 if (resUser) {
//                     submitCartData(resUser, router.query.token);
//                 } else {
//                     toast.open(
//                         <span className="d-flex chek-fms">
//                             <FailSvg/>
//                             <TranslationKeys type="AddAnAddress"/>
//                         </span>
//                     );
//                 }
//             }).catch((err) => console.error(err));
//     }
// }, [router]);
// const submitCartData = (products: submitCardProductsType, token: string | string[] | undefined) => {
//     if (cart.items.length > 0 && products.data !== null) {
//         cart.items.map((product: { product: { id: number; }; quantity: number; id: number; }) => {
//             let pro = products.data.items?.find(
//                 (item) => item.product.id == product.product.id
//             );
//             if (pro == undefined) {
//                 fetch(`https://${httpUrl}/api/checkout/cart/add/${product.product.id}?product_id=${product.product.id}&quantity=${product.quantity
//                     }&api_token=${cartToken.cartToken}${
//                         token ? `&token=${token}` : ""
//                     }`,
//                     {method: "POST"}
//                 )
//                     .then((responce) => responce.json())
//                     .then((res) => console.log(res))
//                     .catch((err) => console.error(err));
//             } else {
//                 dispatch(cartRemoveItem(product.id, cartToken, customer))
//             }
//         });
//     } else if (cart.items.length > 0 && products.data === null) {
//         cart.items.map((product) => {
//
//             fetch(
//                 `${url}/api/checkout/cart/add/${product.product.id}?product_id=${
//                     product.product.id
//                 }&quantity=${product.quantity}&api_token=${cartToken.cartToken}${
//                     router.query.token ? `&token=${router.query.token}` : ""
//                 }`,
//                 {method: "POST"}
//             )
//                 .then((responce) => responce.json())
//                 .then((res) => {
//                 })
//                 .catch((err) => console.error(err));
//         });
//     }
//
//     if (products.data !== null && products.data.items.length > 0) {
//         products.data.items.map((product) => {
//             dispatch(cartRemoveItem(product.product.id, cartToken, customer))
//         });
//     }
// };